<template>
  <!-- 外部权益处理 -->
  <div class="mini-program-back" v-loading="loading">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:" prop="name">
              <el-input v-model="form.name" :clearable="true" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="样本编号:" prop="name">
              <el-input v-model="form.sampleboxNo" :clearable="true" placeholder="请输入样本编号"></el-input>
            </el-form-item>
            <el-form-item label="产品类型:" prop="productId">
              <el-select clearable v-model="form.productId" placeholder="请选择产品名称">
                <el-option :label="obj.label" :value="obj.value" v-for="obj in productList" :key="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码:">
              <el-input v-model="form.phone" :clearable="true" placeholder="请输入手机号码"
                oninput="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="form.status" clearable placeholder="请选择任务状态">
                <el-option :label="obj.label" :value="obj.value" v-for="obj in dynamicList" :key="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search(true)" icon="el-icon-search">查 询
              </el-button>
              <el-button type="primary" @click="creatMailTask(true)" icon="el-icon-circle-plus-outline">创建邮寄任务
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 任务列表 -->
    <el-card shadow="always" class="card" style="margin-top: 20px">
      <el-table :data="tableData" ref="multipleTable" :loading="loading"
        :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#999' }"
        :cell-style="{ 'text-align': 'center' }" style="width: 100%">
        <el-table-column prop="name" label="客户姓名"></el-table-column>
        <el-table-column prop="productName" label="产品类型"></el-table-column>
        <el-table-column prop="phone" label="手机号码"></el-table-column>
        <el-table-column prop="sampleboxNo" label="样本编号"></el-table-column>
        <el-table-column prop="sfBillNo" label="快递订单编号"></el-table-column>
        <el-table-column prop="statusStr" label="任务状态"></el-table-column>
        <el-table-column label="问卷信息" fixed="right" width="220">
          <template slot-scope="scope">
            <div v-if="scope.row.dicCode === '1501' && scope.row.questionAnswerId">
              <el-button @click="reviewTemplate(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="downloadFn(scope.row)" class="updatebutton" type="text" size="small">下载</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row, 'look')" type="text" size="small" class="el_btn">
              详情
            </el-button>
            <el-button v-if="scope.row.status === '14'" @click="handleClick(scope.row, 'sureCust')" type="text"
              size="small" class="el_btn">确认客户信息
            </el-button>
            <el-button v-if="scope.row.status !== '98'" @click="handleClick(scope.row, 'void')" type="text" size="small"
              class="el_btn">
              作废
            </el-button>
            <el-button @click="signed(scope.row)" class="updatebutton" type="text" size="small"
              v-show="Number(scope.row.status) < 14">修改状态为已签收</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper" :current-page="form.pageNo" :page-size="form.pageSize"
        :total="total" background>
      </el-pagination>
      <!-- </div> -->
    </el-card>
    <!-- 添加弹出框 -->
    <el-dialog :visible.sync="visible" width="1100px" title="查看" :close-on-click-modal="false" @close="onClose">
      <lookMiniProSent ref="lookMiniProSent"></lookMiniProSent>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit" :loading="lookLoadong">确 定</el-button>
      </div>
    </el-dialog>
    <applicationForm ref="applicationForm" />
    <!-- 创建邮寄任务 -->
    <el-dialog :visible.sync="mailTaskvisible" width="30%" title="创建邮寄任务" :close-on-click-modal="false"
      @close="onmailClose">
      <mailTask ref="mailTask" @close="onmailClose" v-if="mailTaskvisible"></mailTask>

    </el-dialog>
  </div>
</template>
<script>
import lookMiniProSent from "./components/lookMiniProSent.vue";
import mailTask from '@/views/appointmentManagement/components/mailTask'
import {
  confirmCustomer,
  getProduct, queryQuestionAnswer,
  queryQuestionList,
  queryTaskInfo, taskUpload,
  updateCustomerInfo, updateTask
} from "@/api/modules/miniProSentBack";
import applicationForm from "@/views/questionTemplate/applicationForm.vue";
import { postRequestDown } from "@/api";

export default {
  data() {
    return {
      form: {
        status: "",
        productId: "",
        phone: "",
        name: "",
        sampleboxNo: "",
        samplingType: "5",
        pageNo: 1,
        pageSize: 10
      },
      lookLoadong: false,
      mailTaskvisible: false,
      visible: false,
      productList: [],
      tableData: [],
      total: 0,
      isJQdic:false,
      loading: true,
      dynamicList: [
        { label: "样本待寄回", value: "11" },
        { label: "待取件", value: "12" },
        { label: "已取件", value: "13" },
        { label: "已签收", value: "14" },
        { label: "报告待上传", value: "15" },
        { label: "报告已上传", value: "16" }
      ],
    };
  },
  components: { applicationForm, lookMiniProSent, mailTask },
  created() {
    this.search();
    this.getProductFn();
  },

  methods: {
    // 创建邮寄任务
    creatMailTask() {
      this.mailTaskvisible = true
    },
    onmailClose() {
      this.mailTaskvisible = false
      this.search(true)
    },

    // 已签收
    signed(row) {
      let that = this
      this.$confirm('确认直接修改订单状态吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateTask({
          id: row.id,
          subStatus: '1401'
        })
          .then(res => {

            that.$message.success('修改成功')
            that.search();
          })
      }).catch(() => {
      });

    },
    onSubmit() {

      let sampleList = this.$refs.lookMiniProSent.sampleList
      console.log(this.$refs.lookMiniProSent.sampleList);
      if (Number(this.$refs.lookMiniProSent.form.status) < 15) {
        if (!sampleList.every(v => v.value)) {
          this.$message.warning('请填写样本编号')
          return
        }
        console.log('first', this.$refs.lookMiniProSent.form)
        // 8/15 应产品类型只有 肠道与fs两种 1507 需显示肠道(1502)
        if(this.isJQdic && this.$refs.lookMiniProSent.form.dicCode == '1502'){
          this.$refs.lookMiniProSent.form.dicCode = '1507'
        }
        this.lookLoadong = true
        this.$refs.lookMiniProSent.$refs.form.validate((valid) => {
          if (valid) {
            updateCustomerInfo(this.$refs.lookMiniProSent.form)
              .then(() => {
                this.lookLoadong = false
                this.$message({
                  type: "success",
                  message: "提交成功!"
                });
                this.visible = false;
                this.search();
              })
              .catch(() => {
                this.lookLoadong = false
              });
          } else {
            this.lookLoadong = false
            return false;
          }
        });
      } else if (Number(this.$refs.lookMiniProSent.form.status) >= 15) {
        if (this.$refs.lookMiniProSent.form.result.length === 0) {
          this.$message({
            type: "warning",
            message: "请上传报告或者规划书!"
          });
          return;
        }
        let flag = false;
        this.$refs.lookMiniProSent.form.result.forEach(item => {
          if (!item.url) flag = true;
        });
        if (flag) {
          this.$message({
            type: "warning",
            message: "请上传报告或者规划书!"
          });
          return;
        }
        const fileList = this.$refs.lookMiniProSent.form.result.filter(item => {
          return !item.id;
        });
        if (!fileList.length) {
          this.visible = false;
          return;
        }
        const data = {
          id: this.$refs.lookMiniProSent.form.id,
          files: fileList
        };
        this.lookLoadong = true
        taskUpload(data).then(() => {
          this.lookLoadong = false
          this.$message({
            type: "success",
            message: "提交成功!"
          });
 
          this.visible = false;
          this.search();
        })
        .catch(()=>{
          this.lookLoadong = false
          })
      } else {
        this.visible = false;
      }
    },
    onClose() {
      this.$refs.lookMiniProSent.$refs.form.clearValidate();
      this.$refs.lookMiniProSent.activeIndex = "1";
    },
    getProductFn() {
      getProduct(1)
        .then(res => {
          const setKeyValue = (obj) => {
            let object = {};
            for (let key in obj) {
              object = {
                label: obj[key],
                value: key
              };
            }
            return object;
          };
          this.productList = res.map(item => {
            return setKeyValue(item);
          });
        })
        .catch(() => {
        });
    },
    reviewTemplate(row) {
      queryQuestionAnswer(row.questionAnswerId).then(res => {
        const { answerText } = res;
        this.$refs.applicationForm.dialogVisible = true;
        setTimeout(() => {
          this.$refs.applicationForm.questionnaireFrom = JSON.parse(answerText).application;
          this.$refs.applicationForm.questionnaireFrom.general.address = JSON.parse(answerText).application.general.province + JSON.parse(answerText).application.general.city + JSON.parse(answerText).application.general.area + JSON.parse(answerText).application.general.address;
        }, 200);
      });
    },
    downloadFn(row) {
      postRequestDown(`/questionnaire/downloadQuestion/${row.questionAnswerId}`).then(res => {
        const blob = new Blob([res], { type: res.type });
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = URL.createObjectURL(blob);
        aLink.download = row.productName + "-" + row.name + ".docx";
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
      });
    },
    // 每页数据条数
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    // 当前页
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.search();
    },
    queryTaskInfoFn(taskId) {
      queryTaskInfo(taskId)
        .then(res => {
          this.visible = true;
          setTimeout(() => {
            console.log('res', res)
            console.log('first', res.dicCode == '1507' ? '1502' : res.dicCode)
            this.$refs.lookMiniProSent.form = res;
            if(res.dicCode == '1507'){
              this.isJQdic = true
            } else {
              this.isJQdic = false
            }
              this.$refs.lookMiniProSent.form.dicCode = res.dicCode == '1507' ? '1502' : res.dicCode
            this.$refs.lookMiniProSent.sampleList = res.sampleboxNo.split(',').map(v => {
              return { value: v }
            })
            this.$refs.lookMiniProSent.form.addressList = [res.province, res.city, res.area];
          }, 200);
        })
        .catch(() => {
        });
    },
    // 确认添加
    sureCommit() {

    },
    // 查询
    search(type) {
      if (type) this.form.pageNo = 1;
      this.loading = true;
      queryQuestionList(this.form)
        .then(res => {
          this.total = res.count;
          this.loading = false;
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //   option
    handleClick(row, type) {
      const PopUps = (msg, type) => {
        this.$confirm(msg, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning"
        }).then(() => {
          const Fn = type ? confirmCustomer : updateTask;
          const data = type ? row.id : { id: row.id, subStatus: "9801" };
          Fn(data).then(() => {
            this.$message({
              type: "success",
              message: type ? "确认成功" : "作废成功"
            });
            this.search();
          });
        }).catch(() => {
        });
      };
      switch (type) {
        case "look":
          this.queryTaskInfoFn(row.id);
          break;
        case "sureCust":
          PopUps("是否确认该客户信息?", true);
          break;
        case "void":
          PopUps("是否作废该样本?", false);
          break;
        default:
          break;
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.mini-program-back {
  background: transparent;
}

.block_page {
  margin-top: 20px;
}

.el-input,
.el-select {
  width: 80%;
}

.Equity_main_top {
  padding: 10px 4%;
  background-color: #fff;
}

.Equity_main_bottom {
  padding: 20px 4% 40px;
  margin-top: 15px;
  background-color: #fff;
}

.dialog-footer {
  text-align: center;
  margin: -10px 0 20px;
  // display: flex;
  // padding: 0px 30% 20px;
  // justify-content: space-around;
}

.Selectnum {
  padding: 6px 30px;
  border: 1px solid #bfcbf4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}

.searchFrom>.el-form-item {
  margin-right: 0px !important;
}
</style>
