import { render, staticRenderFns } from "./lookMiniProSent.vue?vue&type=template&id=3a87c7cd&scoped=true&"
import script from "./lookMiniProSent.vue?vue&type=script&lang=js&"
export * from "./lookMiniProSent.vue?vue&type=script&lang=js&"
import style0 from "./lookMiniProSent.vue?vue&type=style&index=0&id=3a87c7cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a87c7cd",
  null
  
)

export default component.exports