<template>
    <div class="look_mini_proSent">
        <el-card>
            <el-row>
                <el-form ref="form" :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small"
                    label-width="90px" :disabled="Number(form.status) >= 15" :rules="rules">
                    <el-form-item label="客户姓名:" prop="name">
                        <el-input v-model="form.name" clearable placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码:" prop="phone">
                        <el-input v-model.trim="form.phone"  
                            placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="产品类型:" prop="dicCode">
                        <el-select style="width: 100%;" v-model="form.dicCode" placeholder="请选择">
                            <el-option v-for="item in productList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="sampleboxNo" label-width="100px">
                        <div class="samplec">
                            <div class="title" style="width: 100px;" slot="label">样本编号:</div>
                            <div class="f">
                                <div class="samp_box">
                                    <el-input v-model="item.value" style="width: clac(100% - 50px);"
                                        v-for="(item, index) in sampleList" @change="changeSampNo" :key="index" clearable placeholder="请输入样本编号">
                                                                    <div class="el-icon-circle-plus" v-if="index === 0" style="color: black;" @click="sampleCLick"
                                    slot="append"></div>
                                <div class="el-icon-delete" style="color: black;" v-if="index !== 0" @click="delSamp(index)" slot="append"></div>
                                    </el-input>
                                </div>

                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="任务状态:">
                        <el-select v-model="form.status" style="width: 100%;" disabled placeholder="请选择任务状态">
                            <el-option :label="obj.label" :value="obj.value" v-for="obj in dynamicList"
                                :key="obj.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="plain" @click="$emit('close')">取 消</el-button>
                        <el-button type="primary" @click="submitTask">确 定</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import { getProduct } from "@/api/modules/miniProSentBack";
import { postRequest } from '@/api'
import { isvalidPhone } from 'src/utils/vaildate'
var validPhone = (rule, val, callback) => {
    console.log(value)
  let value = val.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, "")
    if (!value) {
        callback(new Error('请输入电话号码'))
    } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
    } else {
        callback()
    }
}
export default {
    data() {
        return {
            form: {
                status: '14'
            },
            options: [],
            dynamicList: [
                { label: "待下单", value: "11" },
                { label: "待取件", value: "12" },
                { label: "已取件", value: "13" },
                { label: "已签收", value: "14" },
                { label: "报告待上传", value: "15" },
                { label: "报告已上传", value: "16" }
            ],
            rules: {
                phone: [{ required: true, trigger: 'blur', validator: validPhone }],
                dicCode: [{ required: true, message: '请选择产品类型', trigger: 'input' }],
                name: [{ required: true, message: '请选择客户昵称', trigger: 'input' }],
                sampleboxNo: [{ required: true, message: '请输入样本编号', trigger: 'input' }],
            },
            productList: [],
            sampleList: [{
                value: ''
            }]
        }
    },
    mounted() {
        this.getProductFn();
    },
    methods: {
        changeSampNo(){
            this.form.sampleboxNo = this.sampleList.map(v=>v.value).join()
            console.log('this.form.samp', this.form.sampleboxNo)
        },
        delSamp(i) {
            if (this.sampleList.length > 1)
                this.sampleList.splice(i, 1)
        },
        sampleCLick() {
            this.sampleList.push({ value: '' })
        },
        getProductFn() {
            getProduct(1)
                .then(res => {
                    const setKeyValue = (obj) => {
                        let object = {};
                        for (let key in obj) {
                            object = {
                                label: obj[key],
                                value: key
                            };
                        }
                        return object;
                    };
                    this.productList = res.map(item => {
                        return setKeyValue(item);
                    });
                })
                .catch(() => {
                });
        },
        submitTask() {
            console.log('this.form', this.form)
            this.$refs.form.validate(valid => {
                if (valid) {
                    postRequest('/task/createTask', this.form).then(res => {
                        this.$message({
                            message: '创建邮寄任务成功',
                            type: 'success',
                        })
                        this.$emit('close')
                    })
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.look_mini_proSent {
    .f {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .el-form-item {
        width: 100%;

        ::v-deep .el-form-item__content {
            width: 100%;
        }
    }

    ::v-deep .samplec {

        .samp_box {
            flex: 1;

        }
    }
}
</style>